exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-art-solargraphy-tsx": () => import("./../../../src/pages/art/solargraphy.tsx" /* webpackChunkName: "component---src-pages-art-solargraphy-tsx" */),
  "component---src-pages-art-tsx": () => import("./../../../src/pages/art.tsx" /* webpackChunkName: "component---src-pages-art-tsx" */),
  "component---src-pages-bristol-marketing-agency-tsx": () => import("./../../../src/pages/bristol-marketing-agency.tsx" /* webpackChunkName: "component---src-pages-bristol-marketing-agency-tsx" */),
  "component---src-pages-f-1-prediction-game-2021-tsx": () => import("./../../../src/pages/f1-prediction-game/2021.tsx" /* webpackChunkName: "component---src-pages-f-1-prediction-game-2021-tsx" */),
  "component---src-pages-f-1-prediction-game-2022-tsx": () => import("./../../../src/pages/f1-prediction-game/2022.tsx" /* webpackChunkName: "component---src-pages-f-1-prediction-game-2022-tsx" */),
  "component---src-pages-f-1-prediction-game-2023-tsx": () => import("./../../../src/pages/f1-prediction-game/2023.tsx" /* webpackChunkName: "component---src-pages-f-1-prediction-game-2023-tsx" */),
  "component---src-pages-football-prediction-game-2021-2022-tsx": () => import("./../../../src/pages/football-prediction-game/2021-2022.tsx" /* webpackChunkName: "component---src-pages-football-prediction-game-2021-2022-tsx" */),
  "component---src-pages-football-prediction-game-2022-2023-tsx": () => import("./../../../src/pages/football-prediction-game/2022-2023.tsx" /* webpackChunkName: "component---src-pages-football-prediction-game-2022-2023-tsx" */),
  "component---src-pages-football-prediction-game-2023-2024-tsx": () => import("./../../../src/pages/football-prediction-game/2023-2024.tsx" /* webpackChunkName: "component---src-pages-football-prediction-game-2023-2024-tsx" */),
  "component---src-pages-graphic-design-aberystwyth-university-tsx": () => import("./../../../src/pages/graphic-design/aberystwyth-university.tsx" /* webpackChunkName: "component---src-pages-graphic-design-aberystwyth-university-tsx" */),
  "component---src-pages-graphic-design-freelance-logos-tsx": () => import("./../../../src/pages/graphic-design/freelance-logos.tsx" /* webpackChunkName: "component---src-pages-graphic-design-freelance-logos-tsx" */),
  "component---src-pages-graphic-design-teslanow-tsx": () => import("./../../../src/pages/graphic-design/teslanow.tsx" /* webpackChunkName: "component---src-pages-graphic-design-teslanow-tsx" */),
  "component---src-pages-graphic-design-tsx": () => import("./../../../src/pages/graphic-design.tsx" /* webpackChunkName: "component---src-pages-graphic-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loveyourpostcode-tsx": () => import("./../../../src/pages/loveyourpostcode.tsx" /* webpackChunkName: "component---src-pages-loveyourpostcode-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-swiis-tsx": () => import("./../../../src/pages/swiis.tsx" /* webpackChunkName: "component---src-pages-swiis-tsx" */)
}

